import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Icons, Text } from "@evvve/ui-kit";
import { ChatVariant, messengerApi } from "src/store/messenger";
import {
  useGetChatMateComplex,
  useMessengerViewController,
} from "src/close-part/features/Messenger/hooks";
import { nanoid } from "@reduxjs/toolkit";
import { InputMaxLength } from "src/constants";
import cn from "classnames";
import { ReactComponent as RestrictedToMessageGray400 } from "./assets/restricted_to_message_gray_400.svg";
import s from "./styles.module.scss";
import patchTextareaMaxRowsSupport from "./patchTextareaMaxRowsSupport";

type Props = {
  chatVariant?: ChatVariant;
};

const TEXTAREA_ID = "message-input";
const TEXTAREA_MIN_ROWS_COUNT = 1;
const TEXTAREA_MAX_ROWS_COUNT = 6;

const BottomBar: FC<Props> = ({ chatVariant }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const textareaPatchedRef = useRef(false);

  const { chatId } = useMessengerViewController();

  const [inputValue, setInputValue] = useState("");
  const [sendMessageMutation] = messengerApi.useSendMessageMutation();
  const { chatMateProfile } = useGetChatMateComplex({
    skip: chatVariant !== ChatVariant.PRIVATE,
  });

  useEffect(() => {
    if (textareaRef.current && textareaPatchedRef.current === false) {
      patchTextareaMaxRowsSupport(textareaRef.current);
      textareaPatchedRef.current = true;
    }
  }, []);

  const onInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length <= InputMaxLength.XXXL_4096) {
      setInputValue(event.target.value);
    }
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (inputValue === "" || !chatId) return;

    sendMessageMutation({
      message: inputValue,
      identifier: nanoid(),
      chat_id: chatId,
    });

    setInputValue("");
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      const formSubmitEvent = new Event("submit", {
        cancelable: true,
        bubbles: true,
      });
      event.currentTarget.form?.dispatchEvent(formSubmitEvent);
    }
  };

  const isRestrictedToMessage =
    chatMateProfile && !chatMateProfile.is_can_start_chat;

  if (isRestrictedToMessage) {
    return (
      <div className={s.restrictedToMessageWrap}>
        <div className={s.icon}>
          <RestrictedToMessageGray400 />
        </div>
        <Text className={s.restrictedMessage} size="m" color="grayscale800">
          <span className={s.username}>{chatMateProfile?.user.first_name}</span> has
          restricted messages from other users
        </Text>
      </div>
    );
  }

  return (
    <form className={s.root} onSubmit={onSubmit}>
      <label htmlFor={TEXTAREA_ID} className={s.inputWapper} aria-labelledby="TODO:">
        <textarea
          style={{ width: "100%" }}
          // style={{ height: TEXTAREA_INITIAL_HEIGHT }}
          ref={textareaRef}
          id={TEXTAREA_ID}
          name={TEXTAREA_ID}
          placeholder="Type your message"
          value={inputValue}
          onChange={onInputChange}
          className={s.textarea}
          autoComplete="off"
          rows={TEXTAREA_MIN_ROWS_COUNT}
          onKeyDown={handleKeyPress}
          // NOTE: патч для респонсив <textarea /> - патч обеспечивает автоматическое
          // увеличение/уменьшение высоты компонента в зависимости от количества введенных
          // пользователем строк
          // eslint-disable-next-line react/no-unknown-property
          max-rows={TEXTAREA_MAX_ROWS_COUNT}
        />
      </label>
      <button
        className={cn(s.submitBtn, { [s.isVisible]: inputValue !== "" })}
        type="submit"
        aria-labelledby="TODO:"
      >
        <Icons.Send color="violet600" size="m" />
      </button>
    </form>
  );
};

export default BottomBar;
