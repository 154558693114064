import React, { FC } from "react";
import { TheEventStartsIn15MinMetadata } from "src/store/messenger";
import { NotificationTemplate } from "../notification-template";

type Props = {
  metadata: TheEventStartsIn15MinMetadata;
};

const EventStartsIn15Min: FC<Props> = ({ metadata }) => {
  const { event_id: eventId, event_name: eventTitle } = metadata.data;

  return (
    <NotificationTemplate
      title="The event starts in 15 min"
      event={{ title: eventTitle, link: `/catalog/event/${eventId}` }}
    />
  );
};

export default EventStartsIn15Min;
